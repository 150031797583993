function createModifiers({ menu, modgroup, modPrefixId }) {
  let {autoGenFromTag, discountMultiplier, fixedPrice, priceIf} = modgroup
  // console.log(autoGenFromTag)
  discountMultiplier = Number(discountMultiplier)
  fixedPrice = Number(fixedPrice)

  let modifiers = []
  menu.subMenus.forEach((subMenu) => {
    
    if (!subMenu.tag) {return}
    if (subMenu.tag.indexOf(autoGenFromTag) !== -1) {
      

      subMenu.products.forEach((product) => {
        let { name, price, id, photo, description } = product
        // console.log({autoGenFromTag, discountMultiplier, fixedPrice})
        photo = photo || null
        name = name && name.en
        description = description && description.en    
        let originalPrice = false
        if (discountMultiplier) {
          originalPrice = price
          price = Math.ceil(Number(price) * (1-discountMultiplier) * 100) / 100
        }
        if (Number.isFinite(fixedPrice)) {
          originalPrice = price
          price = fixedPrice
          console.log('fixed price:', price)
        }
        if (priceIf) {
          modifiers.push({
            id: (modPrefixId + id), name: name, priceIf, photo, description
          })
          return
        }
        
        modifiers.push({
          id: (modPrefixId + id), name: name, price: Number(price), originalPrice, photo, description
        })
      })
    }
  })

  return modifiers
}

export default function unzipMenu (menu) {
  try {
    menu.subMenus.forEach((subMenu) => {
      subMenu.products.forEach((product) => {
        if (product.modgroups) {
          product.modgroups.forEach((modgroup, index) => {
            if (!modgroup) { return }
            const autoGenFromTag = modgroup.autoGenFromTag
            const daysOfWeek = modgroup.daysOfWeek
            if (daysOfWeek) {
              const now = new Date()
              const currentDayOfWeek = now.getDay()
              if (daysOfWeek.indexOf(currentDayOfWeek) === -1) {
                delete product.modgroups[index]
              }
            }
            if (autoGenFromTag) {
              // const newModgroup = Object.assign({}, modgroup, {
              //   modifiers: createModifiers({ menu, modgroup })
              // })
              const newMods = createModifiers({ menu, modgroup, modPrefixId: modgroup.id })
              product.modgroups[index].modifiers = newMods
              // console.log(newMods)
            }
          })
        }
      })
    })
  } catch (e) {
    return menu
  }
  // console.log('debug unzip')
  // console.log(menu)


  return menu
}
